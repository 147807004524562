var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Albaranes pendientes "),(_vm.self.formData.albaranes)?_c('span',[_vm._v("("+_vm._s(_vm.self.formData.albaranes.length)+")")]):_vm._e()]),(_vm.tipo)?_c('span',{staticClass:"badge",staticStyle:{"float":"right"}},[_vm._v(_vm._s(_vm.tipo))]):_c('field',{staticStyle:{"top":"5px","right":"20px"},attrs:{"name":"tipo_id","widget":"m2o","label":"Tipo","width":"200px"},on:{"change":_vm.refrescar}})],1),_c('div',{staticClass:"card-body",staticStyle:{"height":"230px","position":"relative"}},[_c('field',{ref:"albaranes",staticClass:"zebraStyle",staticStyle:{"top":"5px","left":"5px"},attrs:{"name":"albaranes","structureRef":'widget_albaranes_pendientes_' + _vm.tipo,"widget":"handsontable","height":350,"width":705,"minRows":15,"readonly":"","columns":[
        {
          name: 'id',
          header: 'Acciones',
          readOnly: true,
          renderer: _vm.rendererAcciones,
        },
        { name: 'ndoc', header: 'Nº' },
        { name: 'fecha', header: 'Fecha', type: 'date2' },
        {
          name: 'cliente_id',
          header: 'Cliente',
          type: 'm2o',
          primary: 'codigo',
          showCode: true,
          label: 'nombre_comercial',
        },
        {
          name: 'representante_id',
          header: 'Representante',
          type: 'm2o',
          primary: 'codigo',
          showCode: true,
          label: 'nombre',
        },
        {
          name: 'total_articulos',
          header: 'Subtotal',
          help: 'Subtotal del pedido',
          type: 'numeric',
          numericFormat: { pattern: '0,0.00' },
          sufix: ' €'
        },
        {
          name: 'total',
          header: 'Total',
          help: 'Total del albaran',
          type: 'numeric',
          numericFormat: { pattern: '0,0.00' },
        },
        {
          name: 'estado',
          header: 'Estado',
          type: 'select',
          options: {
            pendiente: 'Pendiente',
            proceso: 'En proceso',
            enviado: 'Enviado',
            cancelado: 'Cancelado',
            finalizado: 'Finalizado',
          },
          beforeRenderer: function beforeRenderer(td, value) {
            td.classList.add('estado-' + value);
          },
        } ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }