<template>
  <div class="card">
    <div class="card-header">
      <span style="font-weight: bold">Albaranes pendientes
        <span v-if="self.formData.albaranes">({{ self.formData.albaranes.length }})</span></span>
      <span v-if="tipo" class="badge" style="float: right">{{ tipo }}</span>
      <field v-else name="tipo_id" widget="m2o" label="Tipo" width="200px" style="top: 5px; right: 20px"
        @change="refrescar" />
    </div>
    <div class="card-body" style="height: 230px; position: relative; overflow">
      <field ref="albaranes" name="albaranes" :structureRef="'widget_albaranes_pendientes_' + tipo" widget="handsontable"
        :height="350" :width="705" :minRows="15" style="top: 5px; left: 5px;" class="zebraStyle" readonly :columns="[
          {
            name: 'id',
            header: 'Acciones',
            readOnly: true,
            renderer: rendererAcciones,
          },
          { name: 'ndoc', header: 'Nº' },
          { name: 'fecha', header: 'Fecha', type: 'date2' },
          {
            name: 'cliente_id',
            header: 'Cliente',
            type: 'm2o',
            primary: 'codigo',
            showCode: true,
            label: 'nombre_comercial',
          },
          {
            name: 'representante_id',
            header: 'Representante',
            type: 'm2o',
            primary: 'codigo',
            showCode: true,
            label: 'nombre',
          },
          {
            name: 'total_articulos',
            header: 'Subtotal',
            help: 'Subtotal del pedido',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
            sufix: ' €'
          },
          {
            name: 'total',
            header: 'Total',
            help: 'Total del albaran',
            type: 'numeric',
            numericFormat: { pattern: '0,0.00' },
          },
          {
            name: 'estado',
            header: 'Estado',
            type: 'select',
            options: {
              pendiente: 'Pendiente',
              proceso: 'En proceso',
              enviado: 'Enviado',
              cancelado: 'Cancelado',
              finalizado: 'Finalizado',
            },
            beforeRenderer(td, value) {
              td.classList.add('estado-' + value);
            },
          },
        ]" />
    </div>
  </div>
</template>
<script>
import rFormMixin from "./../components/rFormMixin.vue";
import Handsontable from "handsontable";
import Vue from "vue/dist/vue.js";
export default {
  mixins: [rFormMixin],
  props: {
    tipo: String,
  },
  data: function () {
    var self = this;
    return {
      dbAdapter: "albaran_cliente",
      mode: "new",
    };
  },
  methods: {
    rendererAcciones: function (
      instance,
      td,
      row,
      col,
      prop,
      value,
      cellProperties
    ) {
      var self = this;
      Handsontable.renderers.BaseRenderer.apply(this, arguments);
      td.classList.add("td-buttons");
      let $td = window.$(td);
      $td.empty();

      if (instance.getSourceData()[row].id) {
        let html = `
        <div>
            <button class="button" v-tooltip="'Abrir albaran'" @click="abrirAlbaran"><fa-icon icon="external-link-alt" /></button>
            <button class="button" v-tooltip="'Imprimir'"><fa-icon icon="print" /></button>
        </div>
        `;
        var ComponentClass = Vue.extend({
          template: html,
          methods: {
            abrirAlbaran(e) {
              e.stopImmediatePropagation();
              self.app.openWindow("albaran_cliente", {
                itemId: value,
              });
            },
          },
        });
        var ins = new ComponentClass();
        ins.$mount();
        window.$(ins.$el).appendTo($td);
      }

      return $td[0];
    },
    refrescar() {
      var self = this;
      let filter = [
        ["OR", ["estado", "=", "pendiente"], ["estado", "=", "proceso"]],
      ];
      if (self.tipo) filter.push(["tipo_id", "=", self.tipo]);
      else if (self.formData.tipo_id)
        filter.push(["tipo_id", "=", self.formData.tipo_id.codigo]);
      window.DB.getList("albaran_cliente", {
        filter,
        fields: [
          "ndoc",
          "fecha",
          { name: "cliente_id", fields: ["nombre_comercial"] },
          { name: "representante_id", fields: ["nombre"] },
          "total_articulos",
          "total",
          "estado",
        ],
        order: "fecha",
        orderDir: "ASC",
      }).then((res) => {
        self.$set(self.formData, "albaranes", res.data);
      });
    },
  },
  mounted() {
    var self = this;

    self.refrescar();

    window.App.$on("db-save", function (params) {
      if (params.model == "albaran_cliente") {
        let e = params.data.estado;
        let albaran = self.formData.albaranes.find(
          (albaran) => albaran.id == params.id
        );
        if (e == "pendiente" || e == "proceso") {
          if (albaran) {
            albaran.estado = e;
            self.$forceUpdate();
          } else {
            self.refrescar();
          }
        } else if (albaran) {
          self.refrescar();
        }
      }
    });

    window.App.$on("db-delete", function (params) {
      if (params.model == "albaran_cliente") {
        self.refrescar();
      }
    });

  },
};
</script>
<style>
td.estado-pendiente {
  background-color: #ffffbb !important;
  color: black !important;
}

td.estado-proceso {
  background-color: #aadaff !important;
  color: black !important;
}

td.estado-finalizado {
  background-color: #cec !important;
  color: black !important;
}

td.estado-cancelado {
  background-color: #ffbbbb !important;
  color: black !important;
}

td.estado-enviado {
  background-color: #e7d9ff !important;
  color: black !important;
}
</style>